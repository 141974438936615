import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import ky from "ky";
import Product from "../../components/product";

function Products() {
    const API = `https://shop-education-server-api.onrender.com`

    const params = useParams();

    const [ProductObject, setProduct] = useState({});
    const [Also, setAlso] = useState([]);
    const [IsLoading, setIsLoading] = useState(true);

    const NumberFormat  = new Intl.NumberFormat("ru-RU")

    const GetData = async () =>{
        try {
            const data = await ky.get(`${API}/products/${params.id ? params.id : ""}`).json();

            const AlsoArray = await ky.get(`${API}/products/`).json();

            setProduct(data);
            setAlso(AlsoArray.filter(item => item.id !== Number(params.id)));
            setIsLoading(false);

        }catch (error){
            console.error(error);
            window.location.replace("/");
        }
    }

    useEffect(() => {
        GetData();
    }, [Also.length, params.id]);

    if(IsLoading){
        return <h2 className={"title-2"}> Загружает... </h2>
    }

    else if(params && params.id){
        return (
            <React.Fragment>
                <main className="main">
                    <section className="product">
                        <div className="container">
                            <div className="product__row">
                                <div className="product__image">
                                    <img
                                        src={
                                            ProductObject?.image?.startsWith(params.id) ? `${process.env.PUBLIC_URL}/images/${ProductObject.image}` : ProductObject.image
                                        }
                                        alt="error"
                                    />
                                </div>

                                <div className="product__content">
                                    <h2 className="title-2 product__title">{ProductObject.title} </h2>
                                    <p className="price">{NumberFormat.format(ProductObject.price)} <small>руб.</small></p>

                                    <button className="product__button">В корзину</button>

                                    <div className="product__desc">
                                        {
                                            ProductObject?.description?.map((item, index) => <p key={index}> {item}</p>)
                                        }

                                        <p>
                                            <a href="#!" className={"product__link"}>Подробнее об особенностях и преимуществах {ProductObject.title}.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="products">
                        <div className="container">
                            <h2 className="title-2">Смотрите также</h2>
                            <div className="products__row">
                                {
                                    Also.slice(0,3).map(({id,image, title, price}) =>{
                                        return(
                                            <React.Fragment key={id}>
                                                <Product price={price} src={image.startsWith("/") ? process.env.PUBLIC_URL +  image : image} title={title} to={`/products/${id}`} />
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </React.Fragment>
        );
    }
}

export default Products;

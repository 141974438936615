import Home from "../pages/home";
import Products from "../pages/products";
import {Route, Routes} from "react-router-dom";
import React from "react";

export default function Router(){
    const routerArray = [
        {
            id: 1,
            name: "Home",
            path: "/",
            elements: <Home />
        },
        {
            id: 2,
            name: "Product",
            path: "/products/:id",
            elements: <Products />
        }
    ]

    return (
        <React.Fragment>
            <Routes>
                {
                    routerArray.map(({id, path, elements})=>{
                        return (
                            <React.Fragment key={id}>
                            <Route element={elements} path={path} />
                            </React.Fragment>
                        )
                    })
                }
            </Routes>
        </React.Fragment>
    )

}
import React, {useEffect, useState} from 'react';
import Product from "../../components/product";
import ky from "ky";

function Home() {
    const API = `https://shop-education-server-api.onrender.com`
    const [Products, setProducts] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);

    async function GetData(){
        try {
            const data = await ky.get(`${API}/products/`).json();
            setProducts(data);
            setIsLoading(true);
        }catch (error){
            console.error(error)
        }
    }

    useEffect(() => {
        GetData();
    }, [Products.length]);

    return (
        <React.Fragment>
            <main className="main">
                <section className="products">
                    <div className="container">
                        <h2 className="title-2">
                            {
                                IsLoading ? "Магазин" : "Загружает..."
                            }
                        </h2>
                        <div className="products__row">
                            {
                                Products.map(({id,image, title, price, description}, index) =>{
                                    index += 1;
                                    return(
                                        <React.Fragment key={id}>
                                            <Product price={price} src={image.startsWith(index) ? process.env.PUBLIC_URL + "/images/" + image : image} title={title} to={`/products/${id}`} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}

export default Home;

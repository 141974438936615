import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

function Product({src, className, title, to, price}) {
    const NumberFormat  = new Intl.NumberFormat("ru-RU")
    return (
        <div className={`Product ${className}`}>

            <div className="Product__image">
                <img src={src} alt="error"/>
            </div>

            <h3 className={"Product__title"}>{title}</h3>

            <div className="Product__extra">
                <p className="price">{NumberFormat.format(price)} руб.</p>
                <Link className={"Product__button"} to={to}>Смотреть</Link>
            </div>

        </div>
    );
}

Product.propTypes = {
    src: PropTypes.string || undefined,
    alt: PropTypes.string,
    className: PropTypes.string || undefined,
    title: PropTypes.string,
    price: PropTypes.number,
    to: PropTypes.string || undefined
};
Product.defaultProps = {
    src: "https://ih1.redbubble.net/image.1153487532.7283/cposter,small,product,750x1000.2.jpg",
    alt: "error",
    className: "",
    title: "Product name",
    price: 0,
    to: "/products/1"
};

export  default Product;

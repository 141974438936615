import React from "react";
import Router from "./router";

function App() {
    console.clear();
  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
}

export default App;
